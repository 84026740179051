$(function () {
    setSpMenu();
    backToTopButton();
});

function setSpMenu() {
    $spMenuButton = $("#spMenuButton");
    $spMenu = $("#spMenu");

    $("#spMenuButton").click(function () {
        $(this).toggleClass("open");
        $("header").toggleClass("noTransparent");
        $spMenu.slideToggle(240);
    });
}

function backToTopButton() {
    const $backToTop = $("#backToTop");

    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $backToTop.addClass("visible");
        } else {
            $backToTop.removeClass("visible");
        }
    });

    $backToTop.click(function () {
        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
    });
}
